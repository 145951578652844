import me from "../../../assets/images/me.png";
import { Asterisk } from "../../../Utils/Asterisk";
import "./Hero.css";

export default function Hero() {
	return (
		<div className="hero">
			<div className="hero-content">
				<h1 className="hero-title">
					Welcome <Asterisk /> I'm <span className="hero-name">Miles</span>
				</h1>
				<p className="hero-subtitle">A creative & technical full stack developer</p>
			</div>
			<div className="hero-img-wrapper">
				<img src={me} alt="me" className="hero-img" />
				<div className="hero-img-shadow"></div>
			</div>
		</div>
	);
}
