import { useVisibilityObserver } from "../../../Utils/VisibilityObserver";
import { useNavigate } from "react-router-dom";
import { IoIosMore } from "react-icons/io";
import { forwardRef, useRef } from "react";
import * as Si from "react-icons/si";
import "./Footer.css";

const Footer = forwardRef((_, ref) => {
	const navigate = useNavigate();
	const visibleExperience = useRef();
	const visibleContact = useRef();
	const isVisibleExperience = useVisibilityObserver(visibleExperience);
	const isVisibleContact = useVisibilityObserver(visibleContact);
	const goPrivacy = () => navigate("/privacy");
	const goTerms = () => navigate("/terms");

	return (
		<div className="footer-container" ref={ref}>
			<div className={`${isVisibleExperience ? "in-view" : "hidden"} footer-experience`} ref={visibleExperience}>
				<Si.SiJavascript color="#04c748" />
				<Si.SiTypescript size={80} color="#04c748" />
				<Si.SiPython size={80} color="#04c748" />
				<Si.SiHtml5 size={80} color="#04c748" />
				<Si.SiCss3 size={80} color="#04c748" />
				<Si.SiTailwindcss size={80} color="#04c748" />
				<Si.SiReact size={80} color="#04c748" />
				<Si.SiNodedotjs size={80} color="#04c748" />
				<Si.SiPostgresql size={80} color="#04c748" />
				<Si.SiAmazonaws size={80} color="#04c748" />
				<Si.SiMicrosoftazure size={80} color="#04c748" />
				<Si.SiDocker size={80} color="#04c748" />
				<Si.SiGit size={80} color="#04c748" />
				<IoIosMore size={80} color="#04c748" />
				<p>Javascript</p>
				<p>Typescript</p>
				<p>Python</p>
				<p>HTML</p>
				<p>CSS</p>
				<p>Tailwind</p>
				<p>React</p>
				<p>Node.js</p>
				<p>SQL</p>
				<p>AWS</p>
				<p>Azure</p>
				<p>Docker</p>
				<p>Git</p>
				<p>And More</p>
			</div>
			<div className={`${isVisibleContact ? "in-view" : "hidden"} footer-body-wrapper`} ref={visibleContact}>
				<h3>Let's get in touch!</h3>
				<p>
					<a href="mailto:milesbreman@gmail.com" rel="noopener noreferrer" className="contact-link">
						milesbreman@gmail.com
					</a>
				</p>
				<div className="socials-icon-wrapper">
					<a href="https://www.linkedin.com/in/miles-breman/" rel="noopener noreferrer" target="_blank" className="linkedin-link">
						Linkedin
					</a>
				</div>
				<p>Thanks for visiting and I look forward to hearing from you!</p>
				<div className="footer-bottom">
					<h4 className="footer-legal" onClick={goPrivacy}>
						Privacy
					</h4>
					<h4>© 2023 Miles Breman</h4>
					<h4 className="footer-legal" onClick={goTerms}>
						Terms
					</h4>
				</div>
				<a className="resume" href="https://milesbreman.com/resume/" rel="noopener noreferrer" target="_blank">
					View My Resume
				</a>
			</div>
		</div>
	);
});

export default Footer;
