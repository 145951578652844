import { useEffect, useState } from "react";

export const useVisibilityObserver = (elementRef) => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const element = elementRef.current;
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					setIsVisible(true);
				} else {
					setIsVisible(false);
				}
			});
		});
		if (element) {
			observer.observe(element);
		}
		return () => {
			if (element) {
				observer.unobserve(element);
			}
		};
	}, [elementRef]);

	return isVisible;
};
