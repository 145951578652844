import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Projects from "./Pages/Projects/Projects.js";
import useVariableHeight from "./Utils/VariableHeight.js";
import Arca from "./Pages/Overviews/react-arca/Arca.js";
import Privacy from "./Pages/Legals/Privacy.js";
import Terms from "./Pages/Legals/Terms.js";
import Srp from "./Pages/Overviews/srp/Srp.js";
import ConsoleLog from "./Utils/ConsoleLog.js";
import Home from "./Pages/Home/Home.js";
import "./App.css";

export default function App() {
	useVariableHeight();
	ConsoleLog();

	return (
		<Router basename="/">
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/privacy" element={<Privacy />} />
				<Route path="/terms" element={<Terms />} />
				<Route path="/react-arca" element={<Arca />} />
				<Route path="/srp" element={<Srp />} />
				<Route path="/work" element={<Projects />} />
			</Routes>
		</Router>
	);
}
