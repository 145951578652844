import LegalsLayout from "./LegalsLayout";

export default function Terms() {
	return (
		<LegalsLayout>
			<h1>Terms And Conditions</h1>
			<p>Last Updated: September 02, 2023</p>
			<h2>Introduction</h2>
			<p>
				By accessing and using this website, located at https://milesbreman.com/, you accept and agree to be
				bound by the terms and provisions of this agreement. This website is owned and operated by Miles Breman.
			</p>
			<h2>Intellectual Property</h2>
			<p>
				The content on this website, including but not limited to text, images, videos, and code, is owned by
				Mles Breman and may not be used, copied, modified, or distributed without prior written consent.
			</p>
			<h2>Use of the Website</h2>
			<p>
				You may use this website for lawful purposes only. You agree not to use the website in any way that
				could damage the website or general user experience.
			</p>
			<h2>Prohibited Activities</h2>
			<p>
				You may not use this website or any links/contact/email information to transmit or send unsolicited or
				unauthorized advertising, promotional materials, junk mail, spam, or any other form of solicitation. You
				also may not attempt to gain unauthorized access to the website, the server on which the website is
				stored, or any server, computer, or database connected to the website. Doing so may result in legal
				action.
			</p>
			<h2>External Links</h2>
			<p>
				This website may contain links to external websites that are not controlled by Miles Breman. I am not
				responsible for the content or practices of any linked websites. The inclusion of any link does not
				imply endorsement by Miles Breman.
			</p>
			<h2>Limitation of Liability</h2>
			<p>
				You agree that, to the fullest extent permitted by applicable law, Miles Breman will not be liable for
				any loss or damage arising out of or in connection with your use of (or inability to use) this website,
				including but not limited to, indirect or consequential loss or damage, loss of data or profits, or
				damage to your computer systems.
			</p>
			<h2>Changes to These Terms</h2>
			<p>
				I reserve the right to make changes to these Terms and Conditions at any time. Any changes will be
				updated on this page and the last updated date will be revised. Please review this page periodically to
				stay informed.
			</p>
			<h2>Governing Law</h2>
			<p>
				These Terms and Conditions are governed in accordance with the laws of the State of Texas, United
				States.
			</p>
			<h2>Contact Information</h2>
			<p>
				If you have any questions or concerns about these Terms and Conditions, please feel free to contact me
				at milesbreman@gmail.com.
			</p>
		</LegalsLayout>
	);
}
