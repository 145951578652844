import srpImgDesktop2 from "../../../assets/images/srpDesktopOverview.jpeg";
import srpImgMobile2 from "../../../assets/images/srpMobileLeaderboard.png";
import srpImgDesktop from "../../../assets/images/srpDesktopFitness.png";
import srpImgMobile from "../../../assets/images/srpMobileFitness.jpeg";
import ScrollToTopButton from "../../../Components/ScrollUp/ScrollUp";
import Spinner from "../../../Components/Loader/Spinner";
import { srpAssets } from "../../../Utils/Assets";
import Onus from "react-onus";
import "./Srp.css";

export default function Srp() {
	return (
		<Onus assets={srpAssets} loader={<Spinner />}>
			<div className="srp-container">
				<div className="srp-body">
					<h1>Soldier Readiness Platform (SRP)</h1>
					<div className="srp-line"></div>
					<h2>
						Disclaimer: Due to the sensitive nature of this application and its affiliation with the Department of Defense,
						access is strictly limited to individuals possessing the appropriate military credentials. Below, you can see
						screenshots of the application with placeholder data.
					</h2>
					<div className="srp-line"></div>
					<p>
						I had the privilege of contributing to the Soldier Readiness Platform, a vital application designed for the U.S.
						Army. The platform is divided into two main components: the Soldier Portal and the Admin Portal.
					</p>
					<p>
						The Soldier Portal is accessible to any soldier with the appropriate credentials and offers features for
						submitting and tracking health and fitness metrics over time. On the other side, the Admin Portal serves as a
						central hub for army leaders and healthcare professionals to manage and interpret the collected data.
					</p>
					<p>
						My responsibilities spanned developing new features, debugging existing code, managing the infrastructure, and
						maintaining the platform’s overall health. We adopted the Scrum methodology for an agile and efficient development
						process.
					</p>
					<p>
						Currently, the Soldier Readiness Platform is deployed across the entire U.S. Army and stands as the primary tool
						for tracking both individual and unit-level fitness and readiness. My contributions to this project are a
						testament to my commitment to delivering quality software solutions that have a meaningful impact on people's
						lives.
					</p>
					<div className="srp-line"></div>
					<h2>Admin portal home page:</h2>
					<img src={srpImgDesktop2} alt="srp desktop" className="srp-img-desktop" />
					<div className="srp-line"></div>
					<h2>Admin portal fitness page:</h2>
					<img src={srpImgDesktop} alt="srp desktop" className="srp-img-desktop" />
					<div className="srp-line"></div>
					<h2>Soldier portal home page:</h2>
					<img src={srpImgMobile2} alt="srp mobile" className="srp-img-mobile" />
					<div className="srp-line"></div>
					<h2>Soldier portal workout page:</h2>
					<img src={srpImgMobile} alt="srp mobile" className="srp-img-mobile" />
				</div>
				<ScrollToTopButton color="#141414" />
			</div>
		</Onus>
	);
}
