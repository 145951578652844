import srpImgDesktop2 from "../assets/images/srpDesktopOverview.jpeg";
import srpImgMobile2 from "../assets/images/srpMobileLeaderboard.png";
import srpImgDesktop from "../assets/images/srpDesktopFitness.png";
import srpImgMobile from "../assets/images/srpMobileFitness.jpeg";
import rainforest from "../assets/images/rainforest.jpg";
import kids from "../assets/images/kids.png";
import me from "../assets/images/me.png";

export const homePageAssets = [
	{
		type: "image",
		src: rainforest,
	},
	{
		type: "font",
		src: "Manrope-SemiBold",
	},
	{
		type: "font",
		src: "Manrope-Regular",
	},
	{
		type: "image",
		src: kids,
	},
	{
		type: "image",
		src: me,
	},
];

export const arcaPageAssets = [
	{
		type: "video",
		src: "https://raw.githubusercontent.com/mbb10324/react-arca/master/docs/assets/videos/react-arca.mp4",
	},
	{
		type: "font",
		src: "Manrope-Regular",
	},
];

export const srpAssets = [
	{
		type: "image",
		src: srpImgDesktop2,
	},
	{
		type: "image",
		src: srpImgMobile2,
	},
	{
		type: "image",
		src: srpImgDesktop,
	},
	{
		type: "image",
		src: srpImgMobile,
	},
	{
		type: "font",
		src: "Manrope-Regular",
	},
	{
		type: "font",
		src: "Manrope-Black",
	},
];
