import { FaPlayCircle, FaPauseCircle } from "react-icons/fa";
import { forwardRef, useState } from "react";
import "./Skills.css";

const Skills = forwardRef((_, ref) => {
	const [paused, setPaused] = useState(false);

	return (
		<div className="skills">
			<div className={`skills-container ${paused ? "pause" : ""}`} ref={ref}>
				<div className="section-one">
					<h2>diligent</h2>
					<h3>self-motivated</h3>
					<h4>logical thinker</h4>
					<h5>passionate</h5>
				</div>
				<div className="section-two">
					<h2>communicator</h2>
					<h3>respectful</h3>
					<h4>solutions-driven</h4>
					<h5>adaptable</h5>
				</div>
				<div className="section-three">
					<h2>leader</h2>
					<h3>open-minded</h3>
					<h4>thirst for knowledge</h4>
					<h5>teammate</h5>
				</div>
			</div>
			<div className="skills-controller">
				{paused ? (
					<FaPlayCircle className="controller-button" onClick={() => setPaused(false)} />
				) : (
					<FaPauseCircle className="controller-button" onClick={() => setPaused(true)} />
				)}
			</div>
		</div>
	);
});

export default Skills;
