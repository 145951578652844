import { forwardRef, useRef } from "react";
import { MdComputer } from "react-icons/md";
import { TbAntenna } from "react-icons/tb";
import { BiBrain } from "react-icons/bi";
import "./Description.css";
import { useVisibilityObserver } from "../../../Utils/VisibilityObserver";

const Description = forwardRef((_, ref) => {
	const triangleRef = useRef();
	const isVisible = useVisibilityObserver(triangleRef);

	return (
		<div className="description-container" ref={ref}>
			<div className="description-body-start">
				<div className="description-body-start-para">
					<p>
						3 years experience as a full stack software engineer. A deep passion for learning, problem solving, and creating. Proven
						ability to leverage full-stack knowledge to build interactive and user-centered website designs to scale.
					</p>
				</div>
				<MdComputer className="desc-svg1" />
			</div>

			<div ref={triangleRef} className="triangle-container">
				<div className={`${isVisible ? "from-right" : "hidden"} triangle-desc`}>
					<div className={`triangle-body-wrapper ${isVisible ? "from-right-delay" : "hidden"}`}>
						<TbAntenna className="desc-svg2" />
						<p>
							A background with 6 years of experience in satellite communications systems, networking, information technology,
							telecommunications, and managing a team of communications specialists. A passion for all things computer science.
						</p>
					</div>
				</div>
			</div>

			<div className="description-body-end">
				<div className="description-body-end-para">
					<p>Found my niche and love in software, and it feeds the yearn I have to constantly learn and satisfy my mind.</p>
				</div>
				<BiBrain className="desc-svg1" />
			</div>
		</div>
	);
});

export default Description;
