import { projectData } from "../../Utils/ProjectData";
import useBreakpoints from "../../Utils/Breakpoint";
import { FaArrowCircleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "./Projects.css";

export default function Projects() {
	const [selectedProject, setSelectedProject] = useState(null);
	const [showImg, setShowImg] = useState(null);
	const [loadedImages, setLoadedImages] = useState([]);
	const LGbreakpoint = useBreakpoints(1450);
	const SMbreakpoint = useBreakpoints(600);
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	function handleImageLoaded(index) {
		setLoadedImages((prev) => [...prev, index]);
	}

	console.log(loadedImages);

	return (
		<div className="works-container">
			<h3>Projects</h3>
			<div className="works-wrapper">
				{!SMbreakpoint && (
					<div className="work-item-title">
						{!LGbreakpoint && <div />}
						<h4>Name:</h4>
						<h4>Type:</h4>
					</div>
				)}
				{projectData.map((project, index) => {
					return (
						<div style={{ position: "relative" }} key={project.shortTitle}>
							<div
								className={`work-item-wrapper ${selectedProject === project.shortTitle ? "work-item-expanded" : ""}`}
								onMouseEnter={() => setShowImg(index)}
								onMouseLeave={() => setShowImg(null)}
							>
								<div
									className="work-item"
									key={project.shortTitle}
									onClick={(e) => {
										e.preventDefault();
										if (selectedProject === project.shortTitle) {
											setSelectedProject(null);
										} else {
											setSelectedProject(project.shortTitle);
										}
									}}
								>
									{!LGbreakpoint && <div />}
									<h4 style={{ color: SMbreakpoint && selectedProject === project.shortTitle && "var(--P-Green)" }}>
										{project.title}
									</h4>
									{!SMbreakpoint && <h4 className="work-desc">{project.quickDesc}</h4>}
								</div>
								<div className="work-item-content">
									{!LGbreakpoint && <div />}
									<div className="work-item-description">
										{SMbreakpoint && <p>{project.quickDesc}</p>}
										<p>Tech Stack: {project.stack}</p>
										<p>{project.desc}</p>
										<p>Created In: {project.year}</p>
										<button onClick={() => window.open(project.url, "_blank", "noreferrer")}>
											{project.live ? "Visit Site" : "View More"}
										</button>
									</div>
									<img src={`../../assets/images/${project.images.desktop}`} alt={project.title} />
								</div>
							</div>
							<img
								src={`../../assets/images/${project.images.mobile}`}
								alt={project.title}
								onLoad={() => handleImageLoaded(index)}
								className={`work-img ${
									showImg === index && !LGbreakpoint && loadedImages.includes(index) ? "work-img-shown" : "work-img-hidden"
								}`}
							/>
						</div>
					);
				})}
			</div>
			<div className="back-btn">
				<FaArrowCircleLeft onClick={() => navigate("/")} />
			</div>
		</div>
	);
}
