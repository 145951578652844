import ScrollToTopButton from "../../../Components/ScrollUp/ScrollUp";
import { useVisibilityObserver } from "../../../Utils/VisibilityObserver";
import Spinner from "../../../Components/Loader/Spinner";
import { arcaPageAssets } from "../../../Utils/Assets";
import { useRef } from "react";
import Onus from "react-onus";
import "./Arca.css";

export default function Arca() {
	const block1 = useRef();
	const block2 = useRef();
	const block3 = useRef();
	const block4 = useRef();
	const block5 = useRef();
	const block6 = useRef();
	const block7 = useRef();
	const isVisibleBlock1 = useVisibilityObserver(block1);
	const isVisibleBlock2 = useVisibilityObserver(block2);
	const isVisibleBlock3 = useVisibilityObserver(block3);
	const isVisibleBlock4 = useVisibilityObserver(block4);
	const isVisibleBlock5 = useVisibilityObserver(block5);
	const isVisibleBlock6 = useVisibilityObserver(block6);
	const isVisibleBlock7 = useVisibilityObserver(block7);

	const codeString = `
    {
        "extends": ["react-app", "react-app/jest"],
        "rules": {
            "semi": ["warn", "always"],
            "quotes": ["warn", "double"],
            "jsx-quotes": ["warn", "prefer-double"],
            "indent": ["warn", "tab", { "SwitchCase": 1 }],
            "no-tabs": 0,
            "max-len": ["warn", 150]
        }
    }
    `;

	return (
		<div className="arca-container">
			<Onus assets={arcaPageAssets} loader={<Spinner />} handleErrors={true}>
				<video
					data-testid="arca-video"
					src="https://raw.githubusercontent.com/mbb10324/react-arca/master/docs/assets/videos/react-arca.mp4"
					autoPlay
					loop
					muted
					playsInline
				/>
			</Onus>
			<div className="arca-body">
				<div ref={block1} className={`${isVisibleBlock1 ? "in-view" : "hidden"}`}>
					<h2>
						Welcome to react-arca, a lightweight scaffold for getting started with React. Created with the philosophy of
						'just-enough' configuration, react-arca provides the essential tools and settings you need to kickstart your
						application. Unlike more bloated templates that include unnecessary dependencies, react-arca is designed to be
						unobtrusive. It offers you full visibility and control over configuration files and exposes all included
						dependencies. Whether you're a seasoned React developer or new to the framework, react-arca delivers a highly
						customizable and streamlined approach to kickstart your projects.
					</h2>
				</div>

				<div ref={block2} className={`${isVisibleBlock2 ? "in-view" : "hidden"}`}>
					<h2>Getting started:</h2>
					<p>
						To install react-arca run <code>npx react-arca myproject</code>. <br />
						enter your new projects directory by running <code>cd myproject</code>. <br />
						Ensure you are at the root directory and run <code>npm start</code>.
					</p>
				</div>

				<div ref={block3} className={`${isVisibleBlock3 ? "in-view" : "hidden"}`}>
					<h2>To test your app:</h2>
					<ul>
						<li>Read the Jest and @testing-library/react documentation. </li>
						<li>Modify the jest.config.js and assetTransformer.js according to your testing standards.</li>
						<li>Add a setupTests.js if needed.</li>
						<li>
							Run <code>npm run test</code>.
						</li>
						<li>
							By default, we've set up Jest to provide a coverage report for you. This file is git ignored, and you can opt-out
							of providing coverage reports in your package.json from within the "script" section.
						</li>
					</ul>
				</div>

				<div ref={block4} className={`${isVisibleBlock4 ? "in-view" : "hidden"}`}>
					<h2>To build your app:</h2>
					<p>We're using Webpack to create your dev and production builds.</p>
					<ul>
						<li>Read the Webpack documentation.</li>
						<li>Set up your webpack.config.js to optimize the build for your use case.</li>
						<li>
							Run <code>npm run build</code>.
						</li>
						<li>
							Webpack will provide your build output to a 'dist' folder. This file is git ignored, adjust these settings
							according to your needs.
						</li>
					</ul>
				</div>

				<div ref={block5} className={`${isVisibleBlock5 ? "in-view" : "hidden"}`}>
					<h2>To make this app your own:</h2>
					<p>We highly recommend making these changes.</p>
					<ul>
						<li>Add your own favicon.ico. Replace the default favicon.ico within /public/favicon.ico.</li>
						<li>Adjust the head area within /public/index.html to support your project needs.</li>
						<li>
							Within /src/Components, we provided an example with Arca.js. Use this folder to incorporate your own components.
						</li>
						<li>
							Within /src, the files App.css, App.test.js, and App.js are all just templates. Use these to define the rest of
							your project.
						</li>
						<li>
							The /src/index.js is the entry point for the rest of the React app. This is the one file we recommend leaving
							as-is. But hey, hack away.
						</li>
						<li>
							Once you've read through the /README.md we highly recommend replacing all of its contents with your own. If you
							need to reference this file again, you can find it at
							https://github.com/mbb10324/react-arca/react-arca-template#readme.
						</li>
						<li>
							To change the port that your app will run on navigate to /webpack.config.js and at the top of the file you will
							find an "appPort" variable; change this number to change the port that the app will run on.
						</li>
						<li>
							We expose all environment variables throuhgout your React application by default. So all you have to do is create
							a .env at the root of your project, name a variable whatever you would like (ex:{" "}
							<code>GITHUB_LINK=https://github.com/mbb10324/react-arca</code>). Now, from anywhere within your application you
							can define the link with <code>const githubLink = process.env.GITHUB_LINK</code>.
						</li>
						<li>
							We set up some basic linting rules using the .eslintrc file. These are our opinions, and we expect you as the
							developer to fit these to your project needs. To get quickly set up, the project is designed to work with ESLint
							out of the box. You can install the extension here
							https://marketplace.visualstudio.com/items?itemName=dbaeumer.vscode-eslint.
						</li>
						<li>
							After installng the extension. Here is a quick starting configuration to use in your vscodes settings.json which
							will format your code on save so linting errors get automatically fixed as you develop:
						</li>
					</ul>
					<pre>
						<code>{codeString}</code>
					</pre>
				</div>

				<div ref={block6} className={`${isVisibleBlock6 ? "in-view" : "hidden"}`}>
					<h2>Feedback</h2>
					<p>
						If you have any feedback and would like to see things added/removed/changed, create a new issue at
						https://github.com/mbb10324/react-arca/issues
					</p>
				</div>

				<div ref={block7} className={`${isVisibleBlock7 ? "in-view" : "hidden"}`}>
					<h2>Contributing</h2>
					<p>
						react-arca is an open-source library, and we welcome contributions from the developer community. If you're
						interested in contributing, we recommend following these steps located within the /docs folder to get started:
					</p>
					<ol>
						<li>
							Read the **Code of Conduct**: Before contributing, please familiarize yourself with our Code of Conduct, which
							outlines the expected behavior and guidelines for interaction within the react-arca community.
						</li>
						<li>
							Review the **Developer Guidelines**: Take some time to read our Developer Guidelines, which provide helpful
							information and best practices for contributing code, reporting issues, and proposing new features.
						</li>
						<li>
							Explore the **Developer Readme**: The Developer Readme serves as a comprehensive guide for developers working
							with react-arca. It provides detailed information about the library's architecture, key components, and usage
							instructions.
						</li>
					</ol>
					<p>
						Once you've gone through these resources and feel comfortable with the guidelines and documentation, you can start
						contributing to react-arca at https://github.com/mbb10324/react-arca!
					</p>
					<p>
						We greatly appreciate your contributions and look forward to building an inclusive and collaborative developer
						community around react-arca!
					</p>
				</div>
			</div>
			<ScrollToTopButton color="#d4ef21" />
		</div>
	);
}
