import { useLocation } from "react-router-dom";
import { useIsMount } from "./IsMount"; //this relies on the useIsMount function
import { throttle } from "./Helpers"; //this relies on the throttle function
import { useEffect } from "react";

export function useSavedScrollPosition(endpoint, assetsLoaded) {
	const location = useLocation();
	const isMount = useIsMount();

	useEffect(() => {
		if (isMount) return;
		if (location.pathname !== endpoint) return;

		const handleScroll = throttle(() => {
			const scrollPosition = window.scrollY;
			if (scrollPosition < 350) {
				sessionStorage.setItem("scrollPosition", "0");
			} else {
				sessionStorage.setItem("scrollPosition", scrollPosition.toString());
			}
		}, 200);

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
		// eslint-disable-next-line
	}, [location.pathname, isMount]);

	useEffect(() => {
		if (!assetsLoaded || location.pathname !== endpoint) return;

		const savedScrollPosition = sessionStorage.getItem("scrollPosition");

		if (savedScrollPosition) {
			window.scrollTo(0, parseInt(savedScrollPosition));
		}
		// eslint-disable-next-line
	}, [assetsLoaded]);
}
