import { useVisibilityObserver } from "../../../Utils/VisibilityObserver";
import kids from "../../../assets/images/kids.png";
import { forwardRef, useRef } from "react";
import "./About.css";

const About = forwardRef((_, ref) => {
	const aboutTitleRef = useRef();
	const aboutBodyRef = useRef();
	const aboutImg2Ref = useRef();
	const isVisibleTitle = useVisibilityObserver(aboutTitleRef);
	const isVisibleBody = useVisibilityObserver(aboutBodyRef);
	const isVisibleImg2 = useVisibilityObserver(aboutImg2Ref);

	return (
		<div className="about-container" ref={ref}>
			<h3 className={`${isVisibleTitle ? "in-view" : "hidden"}`} ref={aboutTitleRef}>
				About Me
			</h3>
			<div className="about-body-wrapper">
				<img src={kids} alt="kids" className={`${isVisibleImg2 ? "in-view" : "hidden"} about-img`} ref={aboutImg2Ref} />
				<p className={`${isVisibleBody ? "in-view" : "hidden"} about-body-text`} ref={aboutBodyRef}>
					I’m Miles Breman, a full stack developer with a passion for creating, learning, and problem solving. I’m originally
					from Houston, Texas, but I currently live in Fayetteville, North Carolina with my wonderful wife Katie, and our two
					spirited sons, Eli and Wyatt. I just completed 9 years in the U.S. Army, and I'm excited and motivated to step into
					my next chapter in life. If I am not writing code, you can find me spending time with family and friends, hanging
					out in my woodshop, hiking, or playing video games.
				</p>
			</div>
		</div>
	);
});

export default About;
