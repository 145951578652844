import { useEffect, useState } from "react";
import "./Menu.css";

export default function Menu({ sectionRefs }) {
	const [showMenu, setShowMenu] = useState(false);

	function toggleMenu() {
		setShowMenu(!showMenu);
	}

	const { descriptionRef, skillsRef, aboutRef, worksRef, footerRef } = sectionRefs;

	function scrollToSection(ref) {
		ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
		setShowMenu(false);
	}

	useEffect(() => {
		if (showMenu) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
	}, [showMenu]);

	return (
		<>
			<div onClick={toggleMenu} className="menu-button-container">
				<div className="menu-spacer">
					<span className={`${showMenu ? "line1-trans" : ""} line1`}></span>
					<span className={`${showMenu ? "line2-trans" : ""} line2`}></span>
				</div>
			</div>
			<div className={`menu-container ${showMenu ? "menu-open" : "menu-closed"}`}>
				<div className="menu-wrapper">
					<div className="item menu-item-2">
						<h3 onClick={() => scrollToSection(aboutRef)}>About Me</h3>
					</div>
					<div className="item menu-item-3">
						<h3 onClick={() => scrollToSection(worksRef)}>Projects</h3>
					</div>
					<div className="item menu-item-4">
						<h3 onClick={() => scrollToSection(descriptionRef)}>Background</h3>
					</div>
					<div className="item menu-item-5">
						<h3 onClick={() => scrollToSection(skillsRef)}>Soft Skills</h3>
					</div>
					<div className="item menu-item-6">
						<h3 onClick={() => scrollToSection(footerRef)}>Contact</h3>
					</div>
				</div>
			</div>
			<div className={`menu-overlay ${showMenu ? "menu-overlay-open" : "menu-overlay-closed"}`} onClick={toggleMenu} />
		</>
	);
}
