import Description from "../../Components/HomeComponents/Description/Description";
import Footer from "../../Components/HomeComponents/Footer/Footer";
import Works from "../../Components/HomeComponents/Works/Works";
import { useSavedScrollPosition } from "../../Utils/SavedScrollPosition";
import About from "../../Components/HomeComponents/About/About";
import Skills from "../../Components/HomeComponents/Skills/Skills";
import ScrollToTopButton from "../../Components/ScrollUp/ScrollUp";
import Hero from "../../Components/HomeComponents/Hero/Hero";
import Spinner from "../../Components/Loader/Spinner";
import { homePageAssets } from "../../Utils/Assets";
import Menu from "../../Components/Menu/Menu";
import React, { useRef, useState } from "react";
import Onus from "react-onus";
import "./Home.css";

export default function Home() {
	const descriptionRef = useRef(null);
	const skillsRef = useRef(null);
	const aboutRef = useRef(null);
	const worksRef = useRef(null);
	const footerRef = useRef(null);
	const sectionRefs = { descriptionRef, skillsRef, aboutRef, worksRef, footerRef };

	const [assetsLoaded, setAssetsLoaded] = useState(false);
	const handleAllAssetsLoaded = () => {
		setAssetsLoaded(true);
	};

	useSavedScrollPosition("/home", assetsLoaded);

	return (
		<Onus assets={homePageAssets} loader={<Spinner />} handleErrors={true} onLoaded={handleAllAssetsLoaded}>
			<>
				<Menu sectionRefs={sectionRefs} />
				<div className="homeContainer">
					<Hero />
					<About ref={aboutRef} />
					<Works ref={worksRef} />
					<Description ref={descriptionRef} />
					<Skills ref={skillsRef} />
					<Footer ref={footerRef} />
				</div>
				<ScrollToTopButton />
			</>
		</Onus>
	);
}
