export const projectData = [
	{
		title: "Soldier Readiness Platform (SRP)",
		shortTitle: "srp",
		quickDesc: "Health and Fitness Application",
		year: "2023",
		type: "Professional",
		stack: "Typescript, Next.js, React, Node.js, Express, PostgreSQL, TRPC, TailwindCSS, Docker, AWS",
		desc: `The Soldier Readiness Platform is a critical application for the U.S. Army, 
        comprising two portals: the Soldier Portal for tracking individual health and fitness 
        metrics, and the Admin Portal for data management by army leaders and healthcare 
        professionals. As a full-stack developer on this project, I contributed to feature 
        development, debugging, and maintenance. The platform is currently the U.S. 
        Army's primary tool for monitoring fitness and readiness across all units.`,
		live: false,
		url: "https://milesbreman.com/srp",
		images: { mobile: "srpMobileLeaderboard.png", desktop: "srpDesktopOverview.jpeg" },
	},
	{
		title: "Circa 1800",
		shortTitle: "circa-1800",
		quickDesc: "Restaurant Website",
		year: "2024",
		type: "Personal",
		stack: "React, Typescript",
		desc: `Circa 1800 is a modern, responsive website designed for a local restaurant. 
        Leveraging contemporary design principles, I rebuilt the site from the ground up using React and TypeScript. The new website 
        features a visually appealing and user-friendly interface, showcasing the restaurant's menu, ambiance, and contact information 
        with elegance and ease. The redesign not only enhances the restaurant's online visibility but also improves customer engagement 
        and accessibility. This project underscores my ability to revitalize legacy websites, applying modern web development 
        techniques to create impactful, aesthetically pleasing, and functional digital experiences.`,
		live: true,
		url: "https://milesbreman.com/circa/",
		images: { mobile: "circaMobile.png", desktop: "circaDesktop.png" },
	},
	{
		title: "react-figura",
		shortTitle: "react-figura",
		quickDesc: "React Component Library",
		year: "2022",
		type: "Personal",
		stack: "Typescript, react-arca, React, NPM",
		desc: `I am the creator of react-figura, a TypeScript-based npm library that 
        streamlines form handling in React applications. Designed to provide a comprehensive,
        performant, and lightweight solution, the library has garnered over 20,000 installs 
        as of the time of this writing. react-figura offers a suite of 33 fully customizable 
        components that cover an extensive range of input types, thus eliminating 
        the need for boilerplate code and accelerating development cycles. This project 
        showcases my commitment as a developer to write efficient code, understand 
        the nuances of front-end development, and establishes a proven track record 
         of delivering widely-used solutions.`,
		live: true,
		url: "https://react-figura.com/",
		images: { mobile: "figuraMobile.png", desktop: "figuraDesktop.png" },
	},
	{
		title: "Cheetoz",
		shortTitle: "cheetoz",
		quickDesc: "Cheetos Clone Website",
		year: "2023",
		type: "Personal",
		stack: "React, Typescript",
		desc: `Cheetoz is a clone of the iconic Cheetos website's home page, born from my deep appreciation 
        for the original designers and developers. Inspired by their creativity and technical prowess, I set out to recreate 
        the vibrant and engaging online presence of the popular snack brand. Built using React and TypeScript, this project 
        faithfully replicates the dynamic visuals and interactive elements of the Cheetos home page. This project showcases 
        my ability to replicate complex designs and functionality, emphasizing attention to detail and a strong grasp of 
        modern web development techniques. Cheetoz stands as a testament to my admiration for exceptional design and my 
        commitment to delivering high-quality, visually appealing web experiences.`,
		live: true,
		url: "https://milesbreman.com/cheetoz/",
		images: { mobile: "cheetozMobile.png", desktop: "cheetozDesktop.png" },
	},
	{
		title: "react-arca",
		shortTitle: "react-arca",
		quickDesc: "React Boilerplate Framework",
		year: "2023",
		type: "Personal",
		stack: "Javascript, Typescript, React, Node, NPM",
		desc: `React-arca is a open-source npm library that I created and serves as the conrnerstone of my portfolio.
        React-arca encapsulates my commitment to efficiency and customization in software development. 
        Created as a nimble and configurable alternative to create-react-app, this React boilerplate 
        generator fills a distinct void in the ecosystem. It eliminates dependency bloat and empowers 
        developers with greater control over their configurations. Optimized for both simple/small projects 
        as well as full-scale production, React-arca has been a foundational tool in most of my personal 
        projects. Additionally, it's been embraced by thousands of developers for deploying production 
        applications, underscoring its utility and robustness.`,
		live: false,
		url: "https://milesbreman.com/react-arca/",
		images: { mobile: "arcaMobile.png", desktop: "arcaDesktop.png" },
	},
	{
		title: "Three.js Examples",
		shortTitle: "three-examples",
		quickDesc: "3D Scenes",
		year: "2023",
		type: "Personal",
		stack: "Javascript, Three.js, React",
		desc: `Three.js Examples is a dynamic compilation of various 3D scenes created using Three.js, showcasing my exploration and proficiency in 3D graphics. 
        This project serves as both a portfolio and a sandbox environment where I practice and refine my skills in creating interactive and visually stunning 3D scenes. 
        By leveraging reusable components within the repository, I can quickly build and experiment with new scenes, pushing the boundaries of what's possible with 
        Three.js. This project highlights my ability to integrate complex 3D graphics into web applications, demonstrating creativity, technical skill, and a commitment 
        to continuous learning and improvement in the field of 3D web development.`,
		live: true,
		url: "https://milesbreman.com/three/",
		images: { mobile: "threeMobile.png", desktop: "threeDesktop.png" },
	},
	{
		title: "Globs",
		shortTitle: "globs",
		quickDesc: "Lava Lamp Effect",
		year: "2022",
		type: "Personal",
		stack: "Javascript, react-arca, React, AWS",
		desc: `Globs serves as both a personal and professional milestone in my journey 
        as a front-end developer. Initially conceived as an exercise to deepen my understanding 
        of CSS and animations, it quickly transformed into a project of passion when I saw 
        its impact on my son who was captivated by the early iterations of the lava lamp effect 
        I created using pure CSS, his reaction inspired me to evolve this into a fully-fledged application.
        Built on my custom react-arca boilerplate generator, Globs stretches the capabilities of 
        CSS to create a mesmerizing lava lamp effect. The application features over 
        15 unique color variants, making it an ideal screen-saver or background utility. 
        This project is a testament to my technical skills in front-end development 
        and CSS animation.`,
		live: true,
		url: "https://milesbreman.com/globs/",
		images: { mobile: "GlobsMobile.png", desktop: "Globs.png" },
	},
	{
		title: "Calorie Tracker",
		shortTitle: "calorie-tracker",
		quickDesc: "Nutrition Application",
		year: "2024",
		type: "Personal",
		stack: "React-Native, Typescript, React, Node.js, Express, PostgreSQL, AWS",
		desc: `Calorie Tracker is a mobile application designed to help users monitor their daily caloric intake 
        and expenditure, promoting healthier lifestyles. Built with React-Native, the app provides a seamless 
        experience for logging meals and exercises, calculating net caloric intake with ease. The application 
        features an intuitive interface and robust backend powered by Node.js, Express, and PostgreSQL, ensuring 
        smooth performance and reliable data management. Calorie Tracker exemplifies my proficiency in mobile 
        development and my commitment to creating impactful health-focused tools. This project demonstrates 
        my ability to deliver user-centric solutions that blend functionality and user experience.`,
		live: true,
		url: "https://milesbreman.com/calorie-tracker/",
		images: { mobile: "calorieTrackerMobile.png", desktop: "calorieTrackerDesktop.png" },
	},
	{
		title: "react-onus",
		shortTitle: "react-onus",
		quickDesc: "Asset Preloader",
		year: "2023",
		type: "Personal",
		stack: "Typescript, React",
		desc: `React-Onus stands as a testament to my dedication towards enhanced user experience 
        and functionality in web applications. Designed to bridge a glaring gap in the React ecosystem, 
        this innovative asset preloading library serves as a reliable solution to the pervasive issue of asset 
        loading delays. It's designed to handle the nuances of various assets – from images and videos to 
        fonts and SVGs – ensuring users enjoy a smooth browsing experience devoid of unexpected load 
        times or broken visuals. React-Onus embodies my passion for providing developers with tools that
        don't just serve a purpose but elevate the entire development process. Its adoption in numerous 
        projects, both personal and by the broader developer community, is a testament to its utility, 
        performance, and robustness. In fact the loading icons that you may have experienced on this 
        site are powered by react-onus.`,
		live: false,
		url: "https://github.com/mbb10324/react-onus#readme",
		images: { mobile: "onusMobile.png", desktop: "onusDesktop.png" },
	},
	{
		title: "Duro",
		shortTitle: "duro",
		quickDesc: "Fitness Website",
		year: "2023",
		type: "Personal",
		stack: "React, React-Native, Typescript, Node.js, Express, PostgreSQL, AWS",
		desc: `Duro is a comprehensive mobile fitness application designed to empower users to create, perform, and track their workouts over time. 
        Built with React-Native and TypeScript, Duro offers a seamless experience for fitness enthusiasts of all levels. Users can easily 
        build custom workout routines, log their progress, and monitor their fitness journey. The app also features a robust social media 
        component, allowing users to follow, like, and engage with other users' workouts, fostering a supportive and motivational community. 
        With a secure backend powered by Node.js, Express, and PostgreSQL, Duro ensures reliable performance and data management. 
        This project highlights my ability to integrate social features into a mobile app, creating an engaging and interactive user experience.`,
		live: true,
		url: "https://duro.fit",
		images: { mobile: "duroMobile.png", desktop: "duroDesktop.png" },
	},
	{
		title: "Domus",
		shortTitle: "domus",
		quickDesc: "Home Page Generator",
		year: "2024",
		type: "Personal",
		stack: "React, Typescript",
		desc: `Domus is a minimalist homepage builder designed to provide a clean and organized interface for users to access their favorite websites quickly. 
        Inspired by the simplicity of Google Chrome's homepage, Domus allows users to create personalized homepages with links to their most visited sites. 
        Built with React and TypeScript, the application emphasizes ease of use and customization, making it perfect for those who value efficiency and simplicity. 
        This project is particularly significant to me as it serves as my personal homepage, bringing convenience and functionality every time I open my web browser. 
        Domus highlights my ability to develop user-centric applications that enhance daily digital experiences.`,
		live: true,
		url: "https://milesbreman.com/domus/",
		images: { mobile: "domusMobile.png", desktop: "domusDesktop.png" },
	},
	{
		title: "Just The Tip",
		shortTitle: "just-the-tip",
		quickDesc: "Tip Calculator",
		year: "2024",
		type: "Personal",
		stack: "React-Native, Typescript, React",
		desc: `Just The Tip is a mobile application designed to simplify the process of calculating tips. 
        Built with React-Native and TypeScript, the app offers a sleek and intuitive interface where users 
        can quickly input their bill amount and desired tip percentage to get an accurate tip calculation 
        and total bill amount. This project highlights my ability to create practical and user-friendly mobile 
        applications. Just The Tip showcases my proficiency in mobile development, focusing on delivering 
        a seamless and efficient user experience. The application is perfect for everyday use, ensuring 
        hassle-free tipping at restaurants and other service-oriented venues.`,
		live: true,
		url: "https://milesbreman.com/jtt/",
		images: { mobile: "jttMobile.png", desktop: "jttDesktop.png" },
	},
	{
		title: "Mount Up",
		shortTitle: "mount-up",
		quickDesc: "World of Warcraft Addon",
		year: "2023",
		type: "Personal",
		stack: "Lua, XML",
		desc: `Mount Up is a popular World of Warcraft addon that I developed to enhance the player experience by streamlining the process of managing and summoning mounts. 
        The addon allows players to create profiles containing their favorite mounts, enabling quick and easy access to summon them with a single click. 
        Since its release, Mount Up has garnered thousands of downloads and continues to be a valuable tool for the WoW community. 
        I leverage continuous integration and continuous deployment (CI/CD) practices to ensure the addon is regularly updated and compatible with the latest game updates. 
        This project highlights my proficiency in Lua scripting and my commitment to providing ongoing support and improvements for my work.`,
		live: false,
		url: "https://github.com/mbb10324/MountUp#readme",
		images: { mobile: "MountUpMobile.png", desktop: "MountUpDesktop.png" },
	},
	{
		title: "Fortis Bellator",
		shortTitle: "fortis-bellator",
		quickDesc: "Military Support Requests",
		year: "2021",
		type: "Personal",
		stack: "Javascript, react-arca, React, Node.js, Express, PostgreSQL, AWS",
		desc: `During my military training to become a software developer, I spearheaded the 
        development of fortis bellator, one of my first large-scale projects. Designed as a concept 
        tool to digitize and streamline the traditional Request for Support (RFS) process within 
        the military, fortis bellator brings modern technology to age-old procedures. With this 
        application, soldiers and units can swiftly request various types of support for military assets
         -ranging from transportation to satellite imagery- right from their digital devices. 
        These requests are then routed to appropriate subject matter experts and leaders for 
        immediate approval or denial, thus significantly reducing the administrative burden 
        and speeding up decision-making. As a concept piece, fortis bellator not only showcases 
        my technical skills but also my ability to identify and address operational inefficiencies 
        with tailored software solutions. It serves as a powerful example of how technology 
        can revolutionize even the most traditional of processes.`,
		live: true,
		url: "https://milesbreman.com/fortis-bellator/",
		images: { mobile: "fortisMobile.png", desktop: "fortisDesktop.png" },
	},
	{
		title: "Blackjack",
		shortTitle: "blackjack",
		quickDesc: "Video Game",
		year: "2020",
		type: "Personal",
		stack: "Javascript",
		desc: `In the early stages of my development journey, I created a Blackjack game using 
        Vanilla JavaScript, CSS, and HTML with no help from AI or fancy framework tooling. 
        More than just a coding exercise, this project was fun to make and a deep dive into game 
        logic. One of the standout challenges and accomplishments of this project was engineering 
        clean, efficient functions to govern the game's rules and outcomes. Although simplistic,
        blackjack serves as a milestone in my path as a developer, highlighting my aptitude 
        for problem-solving and my passion for coding even before I had an extensive array of 
        tools or languages at my disposal. It was an engaging, hands-on way to solidify my 
        understanding of foundational web technologies and algorithmic thinking.`,
		live: true,
		url: "https://milesbreman.com/blackjack/",
		images: { mobile: "BlackjackTall.png", desktop: "BlackjackWide.png" },
	},
	{
		title: "Death Walk",
		shortTitle: "death-walk",
		quickDesc: "Video Game",
		year: "2023",
		type: "Personal",
		stack: "Typescript, CRA, React, TailwindCSS Node.js, Express, PostgreSQL, AWS",
		desc: `Death Walk is a game built using React, the game features a challenging grid-based landscape 
        where a player must navigate from start to finish while contending with a variety of randomized 
        squares that affect a players health and moves. The project’s standout feature lies in its performance 
        optimizations. Utilizing a sophisticated algorithm built around a 2D array, the game is capable 
        of rendering up to 5,000 squares. Further performance gains are achieved by ensuring that 
        only the squares directly interacted with are re-rendered during each move. This was a fun 
        exercise in creating algorithms for large-scale grid rendering and efficient real-time updates.
        Death Walk showcases my proficiency in React, my knack for algorithmic thinking, and my 
        enthusiasm for tackling computational challenges head-on.`,
		live: true,
		url: "https://milesbreman.com/death-walk/",
		images: { mobile: "deathMobile.png", desktop: "deathDesktop.png" },
	},
	{
		title: "Yami",
		shortTitle: "yami",
		quickDesc: "Online Messaging",
		year: "2023",
		type: "Personal",
		stack: "Javascript, react-arca, React, Node.js, Express, PostgreSQL, AWS",
		desc: `Currently in development, Yami is an ambitious project aimed at creating a secure 
        online messaging platform reminiscent of Signal. Designed with a dual focus on robust 
        security protocols and a user-friendly interface, Yami embodies my commitment to delivering 
        software that is both powerful and accessible. This ongoing endeavor serves as an exploration 
        of secure communication technologies while also providing a canvas for me to create 
        an intuitive, streamlined user experience. While still a demo and concept, Yami
        already showcases my skills in developing real-time applications that prioritize both 
        security and user engagement. Yami stands as a testament to my ability to conceptualize and 
        work on projects that align with current technological trends and needs. It offers a glimpse 
        into my development process and ambition to create meaningful software solutions in 
        an ever-evolving digital landscape.`,
		live: true,
		url: "https://milesbreman.com/yami/",
		images: { mobile: "yamiMobile.png", desktop: "yamiDesktop.png" },
	},
];

export function matchProjectTitle(name) {
	return projectData.find((project) => project.shortTitle === name);
}
