import { FaArrowCircleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import "./Legals.css";

export default function LegalsLayout({ children }) {
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="legals-container">
			<div className="back-button-legal" onClick={() => navigate(-1)}>
				<FaArrowCircleLeft className="back-button-icon-legal" />
			</div>
			<div className="legals-wrapper">{children}</div>
		</div>
	);
}
