import { FaArrowCircleUp } from "react-icons/fa";
import { useState, useEffect } from "react";
import "./ScrollUp.css";

export default function ScrollToTopButton({ color }) {
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		const toggleVisible = () => {
			const scrolled = document.documentElement.scrollTop;
			if (scrolled > 300) {
				setVisible(true);
			} else if (scrolled <= 300) {
				setVisible(false);
			}
		};
		window.addEventListener("scroll", toggleVisible);
		return () => {
			window.removeEventListener("scroll", toggleVisible);
		};
	}, []);

	function scrollToTop() {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}

	return (
		<button className="scroll-up-button">
			<FaArrowCircleUp
				onClick={scrollToTop}
				style={{ display: visible ? "inline" : "none", color: color }}
				className="scroll-up-icon"
			/>
		</button>
	);
}
