import { useVisibilityObserver } from "../../../Utils/VisibilityObserver";
import { forwardRef, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Works.css";

const Works = forwardRef((_, ref) => {
	const [isHovering, setIsHovering] = useState(false);
	const navigate = useNavigate();

	const workTitleRef = useRef();
	const workBodyRef = useRef();
	const workBtnRef = useRef();
	const isVisibleTitle = useVisibilityObserver(workTitleRef);
	const isVisibleBody = useVisibilityObserver(workBodyRef);
	const isVisibleBtn = useVisibilityObserver(workBtnRef);

	return (
		<div className="project-container" ref={ref}>
			<h3 className={`${isVisibleTitle ? "in-view" : "hidden"}`} ref={workTitleRef}>
				Projects
			</h3>
			<p className={`${isVisibleBody ? "in-view" : "hidden"}`} ref={workBodyRef}>
				From full stack software products, open-source libraries, mobile applications, and all things software - click below to explore some
				of my personal and professional projects.
			</p>
			<button
				ref={workBtnRef}
				className={`${isHovering ? "btn-shift" : "btn-static"} ${isVisibleBtn ? "in-view" : "hidden"}`}
				onMouseEnter={() => {
					setIsHovering(true);
				}}
				onMouseLeave={() => {
					setIsHovering(false);
				}}
				onClick={() => navigate("/work")}
			>
				<span className="btn-pri">See My Work</span>
				<span className="btn-sec">See My Work</span>
			</button>
		</div>
	);
});

export default Works;
